<template>
  <div class="container my-24 my-lg-40">
    <div class="row justify-content-center">
      <div class="col col-sm-8 col-md-6 col-lg-5 col-xl-4">
        <div class="row mb-16">
          <header class="col">
            <h2>
              {{ $t('auth.title') }}
            </h2>
          </header>
        </div>

        <v-sheet
            elevation="2"
            rounded="lg"
            class="py-16 px-16 p-xl-32"
        >
          <p class="text-size-14 pb-16">
            {{ $t('auth.message.setLoginAndPassword') }}
          </p>

          <div
            v-for="(input, i) in inputs"
            :key="i"
            class="row mt-8"
          >
            <div class="col">
              <v-field
                :error="!!input.error"
              >
                <label>{{ input.label }}</label>
                <v-input
                  v-model="input.value"
                  :type="input.type"
                  :placeholder="input.placeholder"
                />
                <span class="v-field__error">{{ input.error }}</span>
              </v-field>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <p class="text-size-12 text-color-danger h-32px mb-0">
                {{ errorMessage }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <v-button @click="checkFields">
                {{ $t('auth.button.send') }}
              </v-button>
            </div>
          </div>
        </v-sheet>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VButton,
  VField,
  VInput,
  VSheet
} from '@components/base'
import { getAuthToken } from '@/api'

export default {
  name: 'Auth',

  components: {
    VSheet,
    VField,
    VButton,
    VInput
  },

  data () {
    return {
      errorMessage: '',
      inputs: [
        {
          error: '',
          label: this.$t('auth.field.login.label'),
          name: 'login',
          placeholder: this.$t('auth.field.login.placeholder'),
          type: 'text',
          value: ''
        },
        {
          error: '',
          label: this.$t('auth.field.password.label'),
          name: 'password',
          placeholder: this.$t('auth.field.password.placeholder'),
          type: 'password',
          value: ''
        }
      ]
    }
  },

  methods: {
    checkFields () {
      if (this.inputs.every(input => input.value.length < 1)) {
        this.errorMessage = this.$t('auth.error.emptyFields')
        return
      }

      this.errorMessage = ''
      this.sendData()
    },

    sendData () {
      const sendData = this.inputs.reduce((newObj, item) => {
        newObj[item.name] = item.value
        return newObj
      }, {})

      getAuthToken(sendData)
        .then(response => {
          localStorage.setItem('x-auth-token', response.data.token)
          this.$router.push('/lk_respondent')
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.errorMessage = this.$t('auth.error.notCorrectFields')
          }

          if (error.response.status === 500) {
            this.errorMessage = this.$t('auth.error.any')
          }
        })
    }
  }
}
</script>

<style>
.h-32px {
  min-height: 2rem;
}
</style>
